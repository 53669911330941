<template>

  <div>
    <b-icon :icon="icon" class="mr-1" :class="className" ></b-icon>
    <span :class="{'font-weight-bold': bold}">{{xLabel}}</span>
  </div>

</template>

<script>
  import { ellipsis } from '@/utils'

  export default {
    name: 'surveyLabel',
    props: ["label","icon","bold","className"],

    computed: {

      xLabel() {
        return ellipsis(this.label, 15)
      },

    },

  };
</script>

<style>



</style>
